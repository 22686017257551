body {
  background-color: #03032813 !important;
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(37, 36, 35, 0.836);
  box-sizing: border-box;
}


.product-img {

  float: left;
  width: 25vw;
  height: 35vh;
  padding: 5px;
}

.main {
  display: flex;
  flex-direction: column;
}

.main-item {
  margin: 10px 10px;
  padding: 5px;
  border: 1px solid rgba(194, 194, 199, 0.836);
}

.product-detail {
  margin-top: 10px auto;
}

.cuser>p {
  margin: 10px 10px;
  padding: 5px;
  display: block;
}

h1 {
  color: cadetblue;
  margin: 10px 10px;
}

tr:nth-child(even) {
  background-color: #D6EEEE;
}

.container {
  display: flex;
  flex-direction: row;
  margin: auto;
}


.App-link {
  color: #61dafb;
}